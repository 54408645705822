<template>
  <div class="w-full flex items-center gap-x-2 md:gap-x-4 py-2">
    <CarouselElement
      :items="channels"
      :enable-nav="false"
      :override-options="carouselOptions"
      element-id="channelListCarousel"
      class="w-[80vw]"
    >
      <template #slider-item="{ item, key }">
        <button @click="onChannelClick(item)">
          <div
            :class="[
              selectedChannel?.externalChannelId === item.externalChannelId
                ? 'bg-white/30'
                : 'bg-white/10',
            ]"
            class="flex-shrink-0 rounded-[4px] w-20 md:w-28 aspect-video"
          >
            <div
              :style="{ backgroundImage: `url('${item.images.logo}')` }"
              class="h-full w-full rounded-[4px] object-contain bg-center bg-contain bg-no-repeat"
            ></div>
          </div>
        </button>
      </template>
    </CarouselElement>
    <slot name="epg"> </slot>
  </div>
</template>

<script setup>
import { PAGES_IDENTIFIER } from "@/constants";
const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  path: String,
  carouselOptions: {
    type: Object,
    default: () => null,
  },
  epgPath: {
    type: String,
    required: true,
  },
  emitChannelChange: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits("onChannelChange");

const route = useRoute();
const isKidsMode = useIsKidsMode();
const externalChannelId = ref(route.params.channel || route.query.channel);

const selectedChannel = computed(() => {
  return props.channels.find(
    (channel) => externalChannelId.value === channel.externalChannelId
  );
});

const onChannelClick = async (channel) => {
  externalChannelId.value = channel.externalChannelId;
  if (props.emitChannelChange) {
    emit("onChannelChange", channel);
    return;
  }

  if (isKidsMode.value || route.meta.pageType === PAGES_IDENTIFIER.SPORTS) {
    await navigateTo({
      replace: true,
      query: {
        autoplay: route?.query?.autoplay,
        channel: channel.externalChannelId,
      },
    });
  } else {
    await navigateTo({
      path: useLocalePath()(`/live/${channel.externalChannelId}`),
      replace: true,
      query: { autoplay: route?.query?.autoplay },
    });
  }
};
</script>

<style lang="scss" scoped></style>
