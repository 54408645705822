<template>
  <div>
    <ClientOnly>
      <Head>
        <Title>{{ h1Label }}</Title>
      </Head>
      <PlayerScreensError
        v-if="fetchError !== null"
        :stats="fetchError"
        emit-okay-click
        @on-okay-click="$emit('on-error')"
      ></PlayerScreensError>

      <template v-else>
        <div
          :id="containerId"
          class="relative w-full aspect-video lg:h-screen bg-black flex justify-center items-center"
          @pointerenter.once="onPointerenter"
          @pointerdown.self.stop="onPointerdown"
          @pointermove="onPointermove"
        >
          <video
            id="livePlayer"
            playsinline
            autoplay
            class="w-full h-full object-contain"
          ></video>
          <Transition name="fade" mode="out-in">
            <AdUi
              v-if="store.isAdPlayer"
              :player-id="PLAYERS.LIVE_PLAYER"
            ></AdUi>
            <template v-else>
              <PlayerOverlayBuilderLive
                :media="{
                  activeChannel: activeChannel,
                  activeProgram: activeProgram,
                }"
                :class="{ 'lg:pt-16': !canGoBack }"
              >
                <template #header1-start>
                  <ButtonsIcon
                    v-if="canGoBack"
                    icon-name="IconArrowLeft"
                    class="touch-auto pointer-events-auto rtl:rotate-180"
                    @click="useBackEvent"
                  ></ButtonsIcon>
                  <template v-else>
                    <h2
                      v-if="h1Label"
                      class="text-title-big-bold text-white opacity-[0.87]"
                    >
                      {{ h1Label }}
                    </h2>
                  </template>
                </template>
                <template #header2-start>
                  <h2
                    v-if="canGoBack && h1Label"
                    class="text-title-big-bold text-white opacity-[0.87]"
                  >
                    {{ h1Label }}
                  </h2>
                </template>
                <template #controls-center>
                  <ButtonsIcon
                    v-if="!isXs && !isKidsMode"
                    :enable-hover="false"
                    :breakpoints="{ lg: 40, md: 32, sm: 24 }"
                    iconName="IconCheckList"
                    @click.stop="toggleChannelList"
                  ></ButtonsIcon>
                </template>
                <template #sidebar>
                  <Transition mode="in-out" name="slide">
                    <div
                      v-if="showChannels && !isKidsMode"
                      class="fixed hidden lg:block z-20 top-0 end-0 h-full pt-[calc(5rem)] pb-[100px] transition-all duration-200 pointer-events-none"
                    >
                      <ChannelHeroChannelListVertical
                        v-if="epgConfig"
                        :channels="channels"
                        :path="epgConfig.basePath"
                        :epgPath="epgConfig.epgPath"
                        :emit-channel-change="epgConfig.emitChannelChange"
                        @on-channel-change="(c) => $emit('onChannelChange', c)"
                        class="pointer-events-auto"
                      >
                        <template #epg>
                          <NuxtLink :to="localePath(epgConfig.epgPath)">
                            <ButtonsCta
                              class="w-full variant-secondary text-button-medium py-2"
                              custom-class
                            >
                              {{ $t(epgConfig.label) }}
                            </ButtonsCta>
                          </NuxtLink>
                        </template>
                      </ChannelHeroChannelListVertical>
                    </div>
                  </Transition>
                </template>
              </PlayerOverlayBuilderLive>
            </template>
          </Transition>
        </div>
        <div
          v-if="!isKidsMode"
          id="channelListHori"
          class="block lg:hidden w-screen"
        >
          <ChannelHeroChannelListHorizontal
            v-if="epgConfig"
            :channels="channels"
            :path="epgConfig.basePath"
            :epgPath="epgConfig.epgPath"
            :emit-channel-change="epgConfig.emitChannelChange"
            @on-channel-change="(c) => $emit('onChannelChange', c)"
          >
            <template #epg>
              <div class="grid justify-items-end pr-4">
                <NuxtLink
                  class="inline-flex"
                  :to="localePath(epgConfig.epgPath)"
                >
                  <ButtonsCta
                    class="w-full variant-secondary !text-button-small md:w-[117px] md:!text-button-medium py-2 px-4"
                    custom-class
                  >
                    {{ $t(epgConfig.label) }}
                  </ButtonsCta>
                </NuxtLink>
              </div>
            </template>
          </ChannelHeroChannelListHorizontal>
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { usePlayerStoreWithID } from "@/stores/playerWithId";

const localePath = useLocalePath();
const isKidsMode = useIsKidsMode();

const { isXs } = useBreakpoints();
const { liveShowVideoStart, whichTypeNeedDispatch } = useADtvGTM();

const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  activeChannel: {
    type: Object,
    required: true,
  },
  activeProgram: {
    type: Object,
    required: true,
  },
  epgConfig: {
    type: Object,
    required: true,
  },
  canGoBack: {
    type: Boolean,
    default: () => false,
  },
});

const route = useRoute();

const showChannels = ref(false);
const fetchError = ref(null);
const hideTimeout = ref();
const showHoveredContent = ref();
const adConfig = ref();

const store = usePlayerStoreWithID(PLAYERS.LIVE_PLAYER)();
const containerId = `${PLAYERS.LIVE_PLAYER}-container`;

const abortController = new AbortController();
store.abortSignal = abortController.signal.aborted || false;

const h1Label = computed(() => {
  const title = [];
  title.push(props.activeChannel?.name);
  if (props.activeProgram?.name) {
    title.push(`| ${props.activeProgram?.name}`);
  }
  return title.join(" ");
});

onMounted(async () => {
  useRadioWidget().dismiss();
  await fetchPlayingInfo();
  await loadPlayer();
  // Vender E-connect don't know the business logic, let us use "video_start" on sport channel : (
  if (route.fullPath.indexOf("/sports?") >= 0) {
    whichTypeNeedDispatch("sport", {});
  } else {
    const isLive = props.activeProgram ? useIsLive(props.activeProgram) : true;
    liveShowVideoStart({
      channel: props.activeChannel,
      program: props.activeProgram,
      isLive: isLive,
    });
  }

  await useFmaSdk().logPageNav(usePageId().ADULT__LIVE, {
    contentInfo: {
      contentId: props.activeChannel.externalChannelId,
      contentName: props.activeProgram.name,
    },
  });
});

onBeforeUnmount(() => {
  hideTimeout.value && clearTimeout(hideTimeout.value);
  store.player?.reset();
});

onBeforeRouteLeave(async () => {
  await playerCleanup();
});

const playerCleanup = async () => {
  await usePlayerCleanup(store, abortController);
};

const fetchPlayingInfo = async () => {
  try {
    playInfo.value = await useGetMethod("/api/biz/live/playinfo", {
      // Keep both because for MajidTV in kids mode, we append channel id to query
      channelExternalId: route.params.channel || route.query.channel,
    });

    adConfig.value = {
      protocol: playInfo.value.adProtocol,
      url: playInfo.value.adTraceUrl,
    };
  } catch (error) {
    fetchError.value = error;
  }
};

const preventHideUI = ref(false);
const isPip = ref(false);
const playInfo = ref();

const loadPlayer = async () => {
  await store.initialize("livePlayer");
  await nextTick();
  console.info("[🐥] loadPlayer aborted", store.abortSignal?.aborted);

  const isLive = props.activeProgram ? useIsLive(props.activeProgram) : true;
  await store.loadMedia(
    {
      url: playInfo.value.playingUrl,
    },
    {},
    adConfig.value,
    usePsrConfig().parseLive(props.activeChannel, props.activeProgram, isLive)
  );

  hideUi();
};

function hideUi() {
  clearTimeout(hideTimeout.value); // Clear any existing timeout.
  if (preventHideUI.value) return;
  hideTimeout.value = setTimeout(() => {
    hideTimeout.value && clearTimeout(hideTimeout.value);
    showHoveredContent.value = false;
  }, 3000);
}

/**
 * onPointerenter rules
 * should only work on the mouse
 * show ui
 */
const onPointerenter = ({ pointerType }) => {
  if (pointerType == "touch") return;
  showHoveredContent.value = true;
  hideUi();
};

/**
 * onPointerdown rules
 * onTouch:
 * toggle the ui
 *
 * onMouse:
 * togglePlayback
 */
const onPointerdown = (e) => {
  if (e.pointerType == "touch") {
    showHoveredContent.value = !showHoveredContent.value;

    if (showHoveredContent.value) {
      hideUi();
    }
  } else {
    togglePlayback();
  }
};

/**
 * onPointermove rules
 * should only work on the mouse
 * show ui
 */
const onPointermove = ({ pointerType }) => {
  if (pointerType == "touch") return;
  showHoveredContent.value = true;
  hideUi();
};

// // player controls

function togglePlayback() {
  store.togglePlayback();
}

const toggleChannelList = () => {
  console.info("123");
  showChannels.value = !showChannels.value;
  preventHideUI.value = showChannels.value;
};

watch(
  () => route.query,
  async (newVal) => {
    if (Object.keys(newVal).includes("channel")) {
      if (store.isPip || document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      }

      // await store.player?.reset();
    }
  },
  { deep: true }
);
</script>

<script>
export default { name: "ChannelHeroPlayer" };
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.2s ease-in-out;
}

.slide-leave-active {
  animation: slide-out 0.2s ease-in-out;
}

.slide-leave-to,
.slide-enter-from {
  transform: translateX(0px);
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0.1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0.1;
  }
}
</style>
