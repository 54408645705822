<template>
  <div class="h-full py-4 px-2 flex flex-col gap-y-2 bg-brand-greyblack-300/90">
    <div class="h-full flex flex-col gap-y-2 overflow-y-auto">
      <button
        v-for="channel in channels"
        :id="channel.id"
        :class="[
          selectedChannel?.externalChannelId === channel.externalChannelId
            ? 'bg-white/30'
            : 'bg-white/10',
        ]"
        class="flex-shrink-0 rounded-[4px] thumbnail small aspect-video"
        @click="onChannelClick(channel)"
      >
        <div
          :style="{ backgroundImage: `url('${channel.images.logo}')` }"
          class="h-full w-full rounded-[4px] object-contain bg-center bg-contain bg-no-repeat"
        ></div>
      </button>
    </div>
    <slot name="epg"> </slot>
  </div>
</template>

<script setup>
import { PAGES_IDENTIFIER } from "@/constants";

const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  path: String,
  emitChannelChange: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits("onChannelChange");

const route = useRoute();
const isKidsMode = useIsKidsMode();
const externalChannelId = ref(route.params.channel || route.query.channel);

const selectedChannel = computed(() => {
  return props.channels.find(
    (channel) => externalChannelId.value === channel.externalChannelId
  );
});

const onChannelClick = async (channel) => {
  externalChannelId.value = channel.externalChannelId;
  if (props.emitChannelChange) {
    emit("on-channel-change", channel);
    return;
  }

  if (isKidsMode.value || route.meta.pageType === PAGES_IDENTIFIER.SPORTS) {
    await navigateTo({
      replace: true,
      query: {
        autoplay: route?.query?.autoplay,
        channel: channel.externalChannelId,
      },
    });
  } else {
    await navigateTo({
      path: useLocalePath()(`/live/${channel.externalChannelId}`),
      replace: true,
      query: { autoplay: route?.query?.autoplay },
    });
  }
};

onMounted(() => {
  document
    .getElementById(selectedChannel?.id)
    ?.scrollIntoView({ block: "center" });
});
</script>

<style lang="scss" scoped></style>
