<template>
  <div class="absolute top-0 w-full h-full">
    <PlayerOverlay class="w-full h-full" :can-scrub="false">
      <template #header1-start>
        <slot name="header1-start"></slot>
      </template>
      <template #header2-start>
        <slot name="header2-start">
          <div></div>
        </slot>
      </template>
      <template #header1-end>
        <div class="flex gap-x-4">
          <PlayerOverlayControlsInfo
            :media="media.activeProgram"
          ></PlayerOverlayControlsInfo>
          <UiShareButtonPlayer
            :title="media?.activeChannel?.name ?? ''"
            :url="media?.activeChannel?.shareLink"
            class="touch-auto pointer-events-auto"
          />
        </div>
      </template>
      <template #header2-end> </template>
      <template #actionables></template>
      <template #scrubber> </template>
      <template #controls-start>
        <slot name="controls-start">
          <ButtonsIcon
            :iconName="store.playIconName"
            :enable-hover="false"
            :breakpoints="store.iconBreakpoints"
            @click.stop="store.playerStore.togglePlayback()"
          ></ButtonsIcon>
          <UiTagLive v-if="useIsLive(media.activeProgram)"></UiTagLive>
          <PlayerOverlayControlsVolume
            @on-change="(v) => store.setVolume(v)"
            @on-click="$emit('on-toggle-volume')"
          >
          </PlayerOverlayControlsVolume>
        </slot>
      </template>
      <template #controls-center>
        <slot name="controls-center"></slot>
      </template>
      <template #controls-end>
        <PlayerOverlayControlsQuality></PlayerOverlayControlsQuality>
        <PlayerOverlayControlsPip></PlayerOverlayControlsPip>
        <PlayerOverlayControlsFullscreen></PlayerOverlayControlsFullscreen>
      </template>
      <template #indicator> </template>
    </PlayerOverlay>
    <slot name="sidebar"></slot>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { usePlayerUi } from "@/stores/playerUi";

onMounted(() => {
  store.registerKeyboardEvents();
});

const props = defineProps({
  media: {
    type: Object,
    required: true,
  },
  playerId: {
    type: String,
    required: true,
  },
});

const store = usePlayerUi();
store.setPlayer(PLAYERS.LIVE_PLAYER);

watch(
  () => store.playerStore?.playbackState,
  (playbackState) => {
    if (playbackState) {
      store.isLoading = false;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped></style>
